import __request__ from 'routing/request';
interface ConfigResponse {
    readonly okta_org_url: string;
    readonly base_url: string;
    readonly okta_external_idp_id: string;
    readonly oauth_client_id: string;
}
/**
 * Get configuration
 */
export function getConfig(): Promise<ConfigResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.cloud/auth/config', null, {}, {
        'Accept': ['application/json']
    });
}
