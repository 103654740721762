import __request__ from 'routing/request';
interface HistogramTechnique {
    readonly count: number;
    readonly technique: string;
    readonly id: string;
}
interface EventsHistogramStatus {
    readonly usable: boolean;
}
interface HistogramTactic {
    readonly count: number;
    readonly id: string;
    readonly tactic: string;
}
interface TimeRange {
    readonly type: string;
}
interface TechniquesHistogramResponse {
    readonly techniques: HistogramTechnique[];
}
interface Bucket {
    readonly count: number;
    readonly start_date: string;
}
interface EventsHistogramResult {
    readonly buckets: EventsBuckets;
}
interface SecurityEventsSearchRequest {
    readonly filter: SecurityEventsFilter;
    readonly per_page: number;
    readonly sort_direction: 'asc' | 'desc';
    readonly timerange: TimeRange;
    readonly timezone: string;
    readonly query: string;
    readonly page: number;
    readonly sort_by: string;
}
interface EventsBuckets {
    readonly alerts: Bucket[];
    readonly events: Bucket[];
}
interface TacticsHistogramResponse {
    readonly tactics: HistogramTactic[];
}
interface SecurityEventsFilter {
    readonly alerts: 'include' | 'exclude' | 'only';
    readonly associated_assets: string[];
    readonly owner: string[];
    readonly risk_score: number;
    readonly event_definitions: string[];
    readonly priority: string[];
    readonly mitre_categories: string[];
    readonly status: ('NEW' | 'INVESTIGATING' | 'FALSE_POSITIVE' | 'CLOSED')[];
}
/**
 * Build histogram of events over time
 */
export function histogram(JSONbody?: SecurityEventsSearchRequest): Promise<EventsHistogramResult> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/security_events/histogram/alerts', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Export histogram data
 * @param filename filename
 */
export function exportHistogram(JSONbody?: SecurityEventsSearchRequest, filename?: string): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.securityapp/security_events/histogram/alerts/${filename}`, JSONbody, {}, {
        'Accept': [
            'text/csv',
            'application/json',
            'application/yaml',
            'application/xml',
            'application/vnd.ms-excel'
        ]
    });
}
/**
 * Check prerequisites of tactics/techniques histogram
 */
export function status(): Promise<EventsHistogramStatus> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/security_events/histogram/status', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Provide histogram of events over Mitre Att&ck tactics/techniques
 */
export function tacticsHistogram(JSONbody?: SecurityEventsSearchRequest): Promise<TacticsHistogramResponse> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/security_events/histogram/tactics', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Export Tactics histogram data
 * @param filename filename
 */
export function exportTacticsHistogram(JSONbody?: SecurityEventsSearchRequest, filename?: string): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.securityapp/security_events/histogram/tactics/${filename}`, JSONbody, {}, {
        'Accept': [
            'text/csv',
            'application/json',
            'application/yaml',
            'application/xml',
            'application/vnd.ms-excel'
        ]
    });
}
/**
 * Provide histogram of events over Mitre Att&ck tactics/techniques
 */
export function techniquesHistogram(JSONbody?: SecurityEventsSearchRequest): Promise<TechniquesHistogramResponse> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/security_events/histogram/techniques', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Export Techniques histogram data
 * @param filename filename
 */
export function exportTechniquesHistogram(JSONbody?: SecurityEventsSearchRequest, filename?: string): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.securityapp/security_events/histogram/techniques/${filename}`, JSONbody, {}, {
        'Accept': [
            'text/csv',
            'application/json',
            'application/yaml',
            'application/xml',
            'application/vnd.ms-excel'
        ]
    });
}
