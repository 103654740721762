import __request__ from 'routing/request';
interface AssetImportBackendConfig {
    readonly type: string;
}
interface AssetImportTestRequest {
    readonly asset_import_backend_configuration: AssetImportBackendRequest;
}
interface AssetImportBackendRequest {
    readonly scheduled_config: ScheduledConfigView;
    readonly description: string;
    readonly priority: number;
    readonly category: string[];
    readonly title: string;
    readonly config: AssetImportBackendConfig;
}
interface ClearAssetSyncResponse {
    readonly assets_cleared: number;
}
interface ScheduledConfigView {
    readonly enabled: boolean;
    readonly interval_hours: number;
}
/**
 * Clear all assets imported from a specific backend.
 */
export function clear(importId: string, sourceId: string): Promise<ClearAssetSyncResponse> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.securityapp/assets/sources/${sourceId}/import-backends/use/${importId}/clear`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Import Assets
 */
export function importAssets(sourceId: string, importId: string): Promise<unknown> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/assets/sources/${sourceId}/import-backends/use/${importId}/import`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Test asset-import backend configuration
 */
export function testExisting(sourceId: string, importId: string): Promise<unknown> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/assets/sources/${sourceId}/import-backends/use/${importId}/test`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Test asset-import backend configuration
 */
export function testNew(sourceId: string, importId: string, JSONbody: AssetImportTestRequest): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.securityapp/assets/sources/${sourceId}/import-backends/use/${importId}/test`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
